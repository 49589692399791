import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import withPageEntry from '../../withPageEntry';
import * as NavUtil from '../../Utils/NavUtil';
import CustomerSupportForm from '../../Components/CustomerSupportForm';

function CustomerSupport(props) {
  const [dimension] = useOutlet('dimension');
  useOutlet('locale');
  useOutlet('footerConfig');
  const footerConfig = NavUtil.getFooterConfig(props);
  const isMobile = dimension.rwd === 'mobile';

  return (
    <Wrapper rwd={isMobile}>
      <div className="content">
        <CustomerSupportForm />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f6f6f6;
  padding-top: var(--topNavBarHeight);
  min-height: var(--contentMinHeight);
  padding: ${(props) =>
    props.rwd
      ? `calc(var(--topNavBarHeight) + 22px) 20px 40px 20px`
      : `calc(var(--topNavBarHeight) + 60px) 50px 40px 50px`};

  & > .content {
    margin: 0 auto;
    max-width: var(--contentMaxWidth);
    width: 100%;
    text-align: center;

    & .logo {
      width: 200px;
      object-fit: contain;
      margin-bottom: 20px;
    }

    & .subtitle {
      color: #aaa;
      margin-bottom: 20px;
    }
  }
`;

export default withPageEntry(CustomerSupport);
